/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faStar } from '@fortawesome/free-solid-svg-icons';

import config from '../../config';

import ErrorMessage from '../../components/ErrorMessage';

const api = config.createApiUrl('identity', `/auth/user/new`, config.URL_TYPES.API);
const url = config.createApiUrl('events', `/`, config.URL_TYPES.BASE);

const UserSignupForm = ({isNewUser, toggleUser}) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const [showPassword, setShowPassword] = useState(false);
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }    

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const response = await axios.post(api, {
                email,
                password,
                username
            });
    
            if (response?.status === 201) {
                window.location.href = `${url}`;
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error(error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setErrorMessage(error.response.data.message);  // Display the error message from server
            } else {
                // Something happened in setting up the request that triggered an Error
                setErrorMessage(error.message);
            }
        }
    };
    
    return (
        <div className='form-container'>
            <form>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="input-group">
                    <div className='input-container'>
                        <label>
                            Username
                        </label>
                        <div className='
                            input-container-input_elements 
                            input-container-input_elements_exception
                        '>
                            <div>
                                <FontAwesomeIcon icon={faStar} />
                            </div>
                            <input
                                type="text" 
                                value={username} 
                                onChange={(e) => setUsername(e.target.value)} 
                                placeholder="What name vibes with you?" 
                                required 
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                    </div>
                </div>
                <div className="input-group">
                    <div className='input-container'>
                        <label>
                            Email
                        </label>
                        <input 
                            type="text" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            placeholder="Email" 
                            required 
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                <div className="input-group">
                    <div className='input-container'>
                        <label>
                            Password
                        </label>
                        
                        <div className='input-container-input_elements'>
                            <input 
                                type={showPassword ? 'text' : 'password'}
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                placeholder="Password" 
                                required 
                                onKeyDown={handleKeyDown}
                                />
                            <div className="login-input-toggle" onClick={togglePasswordVisibility}>
                                {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                            </div>
                        </div>
                        <div className="form_help_text">
                            8 characters, includes one of each: uppercase, lowercase, number, special character
                        </div>
                    </div>
                </div>
                <div onClick={handleSubmit} className="form_button button">
                    Sign Up
                </div>
                <div className="form_toggle" onClick={() => toggleUser()}>
                    {isNewUser ? "Existing User? Log In" : "New User? Sign Up"}
                </div>
            </form>
        </div>
    );
};

export default UserSignupForm;
