/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPassword.css'; // Assume you have some basic CSS for styling

import ErrorMessage from '../../components/ErrorMessage'

import config from '../../config';
import { useNavigate } from 'react-router-dom';

const api = config.createApiUrl('identity', `/reset/request-password-reset`, config.URL_TYPES.API);

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState(null);

  const navigate = useNavigate()

  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
          handleSubmit(event);
      }
  }    

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Handle password reset request here
    try {
      const response = await axios.post(api, {
          email,
      });

      // Handle the response accordingly
      if (response?.status === 202) {
        setMessage(response.data.message)
      } else {
        setErrorMessage('Invalid email or password');
    }
    } catch (error) {
      console.log(error)
      // Handle errors, such as showing a message to the user
      setErrorMessage(error?.response?.data?.message)
      console.error('Error sending password reset email: ', error);
    }
  };

  if (message) {
    return (
      <div className="forgot-password-page">
        <ErrorMessage errorMessage={message} />
      </div>
    )
  }

  
  const loadLogin = () => {
    navigate('/')
  }

  return (
    <div className="forgot-password-page">
      <h1>Forgot Password</h1>
      <form>
        <div className='input-group'>
          <div className='input-container'>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                placeholder='Email'
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                required
              />
              <div className='form_help_text'>
                  Enter your Email address and we will send you a link to reset your password.
              </div>
          </div>
        </div>
        <div className="button" onClick={handleSubmit}>Send Reset Link</div>
      </form>
      {errorMessage &&
        <ErrorMessage errorMessage={errorMessage} />
      }
      
      <h4 className="c2a form_toggle" onClick={() => loadLogin()}>
          Nevermind, I remember now
      </h4>
    </div>
  );
};

export default ForgotPassword;