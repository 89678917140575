/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';

import './ProfileImage.css'

const imageBaseUrl = "https://revibe-life-assets.s3.amazonaws.com/";
const pattern = /^critter\d{3}\.webp$/;

const ProfileImage = ({ onSelectImage, profilePicture, selectedImage }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    // Initialize or update the image URL
    let imagePath = imageBaseUrl + 'critter021.webp';
    if (profilePicture != null && pattern.test(profilePicture)) {
        imagePath = `${imageBaseUrl}${profilePicture}`
    }
    setImageUrl(imagePath);
  }, [profilePicture]); // Empty dependency array for componentDidMount behavior

  return (
    <div className={`profile_image ${selectedImage == profilePicture ? 'selected' : ''}`}>
      <img 
        onClick={() => onSelectImage(profilePicture)}
        src={imageUrl}
        alt={profilePicture} />
    </div>
  );
};
export default ProfileImage;
