/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Loading from '../../components/Loading';

import config from '../../config';

import AccountInfo from '../../components/AccountInfo';
import UpdateUserForm from '../../components/UpdateUserForm/UpdateUserForm';
import GroupSection from '../../components/GroupSection'

import ErrorMessage from '../../components/ErrorMessage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToolbox } from '@fortawesome/free-solid-svg-icons';

import { faCalendarDays, faComments, faMagnifyingGlass, faCircleUser } from '@fortawesome/free-solid-svg-icons';

import './UserProfile.css'

const api = config.createApiUrl('identity', `/user/profile`, config.URL_TYPES.API);
const orgAPI = config.createApiUrl('directory', `/org/`, config.URL_TYPES.API);

const forumURL = config.createApiUrl('forum', `/`, config.URL_TYPES.BASE);
const eventURL = config.createApiUrl('events', `/`, config.URL_TYPES.BASE);
const eventStatsURL = config.createApiUrl('events', `/event_stats/org`, config.URL_TYPES.API);

const UserProfile = () => {
  const [orgs, setOrgs] = useState([]);
  const [eventStats, setEventStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [needAuth, setNeedAuth] = useState(false);

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    username: '',
    profile_picture: '',
    isEditMode: false,
  });

  useEffect(() => {
    // Fetch user data from API
    axios.get(api)
      .then(response => {
        const { 
          name, 
          email, 
          username, 
          profile_picture, 
        } = response.data;
        setUserData({ ...userData, name, email, username, profile_picture });
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }, []);
  

  const fetchEventStatsForOrg = async (org_ids) => {
      const startDate = new Date().getTime() - 604800000;
      const endDate = new Date().getTime();

      if (org_ids.length < 1) {
        let data = []; // The reduce function will calculate 0 events 
        data['startDate'] = startDate;
        data['endDate'] = endDate;
        setEventStats(data);
        return
      }
      try {
          const params = {
              start_date: startDate,
              end_date: endDate,
              org_id: org_ids
          };
          const res = await axios.get(eventStatsURL, { params });
          // Attach date range to stats response
          res.data['startDate'] = startDate;
          res.data['endDate'] = endDate;
          setEventStats(res.data);
      } catch (err) {
          if (err.response?.status === 401) {
              setNeedAuth(true);
          } else {
              console.error(err);
              setErrorMessage('There was a server error.');
          }
      } finally {
          setIsLoading(false);
      }
  };

  useEffect(() => {
    const fetchUserOrgs = async () => {
        try {
            const res = await axios.get(orgAPI); 
            setOrgs(res.data.items);
            fetchEventStatsForOrg(res.data.recent_org_ids)
        } catch (err) {
            if (err.response?.status === 401) {
                setNeedAuth(true)
            } else {
                console.error(err);
                setErrorMessage('There was a server error.')
            }
        } finally {
            setIsLoading(false);
        }
    };

    fetchUserOrgs();
}, []);

  const handleEditToggle = () => {
    setUserData({ ...userData, isEditMode: !userData.isEditMode });
  };

  function truncateCharacters(str, maxLength) {
      if (str.length <= maxLength) {
        return str; // Returns original string if string is equal to or less than max length
      } else {
        const truncatedString = str.substring(0, maxLength); // Get the substring up to the maxLength
        // Find the last space within the truncated substring to avoid cutting off a word
        const lastSpaceIndex = truncatedString.lastIndexOf(' ');
        if (lastSpaceIndex !== -1) {
          return truncatedString.substring(0, lastSpaceIndex) + '...'; // Add dots at the last space
        } else {
          return truncatedString + '...'; // If no space found, add dots at the end of the substring
        }
      }
  }

  const OrgListNavWrapper = ({children}) => {
      return (
          <>
              {children}
              {/* Nav */}
              <div className='nav' style={{zIndex: 0}}>
                <Link
                  className="fa-button"
                  to={`${eventURL}`}
                >
                    <FontAwesomeIcon
                        icon={faCalendarDays}
                        size="xl" />
                </Link>
                <Link
                    className="fa-button"
                    to={`${forumURL}`}
                    >
                    <FontAwesomeIcon
                        icon={faComments}
                        size="xl" />
                </Link>
                <Link
                  className="fa-button"
                  to={`${eventURL}search`}
                >
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        size="xl" />
                </Link>
                <Link
                  className="fa-button"
                  to="/user-profile"
                >
                    <FontAwesomeIcon
                        icon={faCircleUser}
                        size="xl" />
                </Link>
              </div>
          </>
      )
  }

  if (isLoading || needAuth) {
      return (
          <OrgListNavWrapper>
              <Loading />
          </OrgListNavWrapper>
      )
  }

  if (errorMessage) {
      return (
          <OrgListNavWrapper>
              <ErrorMessage errorMessage={errorMessage} />
          </OrgListNavWrapper>
      )
  }

  return (
      <div>
        {/* Edit Account Info, Otherwise Show Information */}
        {userData.isEditMode ? (
          <UpdateUserForm
            userData={userData}
            setUserData={setUserData}
            handleEditToggle={handleEditToggle}
          />
        ) : (
          <OrgListNavWrapper>
            <div className="user-profile-margin">
              {/* Resources */}
              <div className='resources c2a'>
                <b>
                    <Link to={`${eventURL}resources`}>
                        <div className='user-profile-resources_button_container'>
                          <div className="button gradient_button">
                            <FontAwesomeIcon
                                icon={faToolbox}/>
                            Resources
                          </div>
                        </div>
                    </Link>
                    <br />
                    Find useful information to create a great vibe!
                </b>
              </div>

              {/* Account Info */}
              <AccountInfo
                userData={userData}
                setUserData={setUserData}
                truncateCharacters={truncateCharacters} 
                handleEditToggle={handleEditToggle}
              />

              {/* Groups */}
              <GroupSection
                orgs={orgs} 
                eventStats={eventStats}
                truncateCharacters={truncateCharacters} 
              />
            </div>
          </OrgListNavWrapper>
        )}

      </div>
  );
};

export default UserProfile;
