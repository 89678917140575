/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

import ProfileImage from '../../components/ProfileImage'

const orgURL = config.createApiUrl('directory', `/`, config.URL_TYPES.BASE);

const AccountInfo = ({userData, truncateCharacters, handleEditToggle, admin}) => {
    return (
      <div className='user_profile_block'>
        {/* IDEA: Refactor this to be more resuable */}
        {!admin &&
        <>
          <div className='user_profile_heading'>
            <h4>Account Info</h4>
              <div onClick={handleEditToggle} className='button'>
                Edit
              </div>
          </div>
          <h6>
            Choose a cute critter and username to create nice vibes
          </h6>
        </>
        }
        <div className='user_profile_image_container'>
          <ProfileImage
            onSelectImage={() => {}}
            selectedImage={null}
            profilePicture={userData.profile_picture ? userData.profile_picture : 'default'} />
        </div>
        <div className='org-page_details'>
            <div className='event_org_info_item'>
                <p className='event_org_info_item_wrapper'>
                    <span className='event_org_info_label'>Username</span>
                </p>
                <p className='event_org_info_value'>
                  {
                    userData.username 
                    ? truncateCharacters(userData.username, 16)
                    : ''
                  } 
                  {
                    !userData.username && '(None)'
                  } 
                </p>
            </div>

            <div className='event_org_info_item'>
                <p className='event_org_info_item_wrapper'>
                    <span className='event_org_info_label'>Email</span>
                </p>
                <p className='event_org_info_value'>
                  {
                    userData.email 
                    ? truncateCharacters(userData.email, 16)
                    : ''
                  } 
                </p>
            </div>

            {admin &&
              <>
                <div className='event_org_info_item'>
                    <p className='event_org_info_item_wrapper'>
                        <span className='event_org_info_label'>ID</span>
                    </p>
                    <p className='event_org_info_value'>
                      { userData.id } 
                    </p>
                </div>
                <div className='event_org_info_item'>
                    <p className='event_org_info_item_wrapper'>
                        <span className='event_org_info_label'>Created</span>
                    </p>
                    <p className='event_org_info_value'>
                      { userData.created_date } 
                    </p>
                </div>
              </>
            }
        </div>
      </div>
    );
  }
export default AccountInfo;
