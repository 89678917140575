/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import { Link } from 'react-router-dom';
import './InvalidToken.css'; // Assume you have some basic CSS for styling

import ErrorMessage from '../../components/ErrorMessage'

const InvalidToken = () => {
  return (
    <div className="invalid-token-page">
      <h1>Reset Password Link Expired</h1>
      <ErrorMessage errorMessage={
        'The reset password link is invalid or has expired. Please try requesting a new one.'
      }/>
      <Link to="/forgot-password" className="button">
        Request a New Link
      </Link>
    </div>
  );
};

export default InvalidToken;