/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import axios from 'axios';
import Cookies from 'js-cookie';

import config from './config';
const url = config.createApiUrl('identity', `/`, config.URL_TYPES.BASE);

axios.interceptors.request.use(function (config) {
  // Extract CSRF token from cookie
  const csrfToken = Cookies.get('csrf_access_token');
  if (csrfToken) {
      // Set CSRF token in headers
      config.headers['X-CSRF-TOKEN'] = csrfToken;
  }
  return config;
});

axios.interceptors.response.use(
  response => response,  // Pass through successful responses.
  error => {
    if (error.response.status === 401) {
      // The token has expired or is otherwise invalid.
      // Only redirect if we're not already at the login page
      if (window.location.href !== url) {
        window.location.href = url;
      }
    } else {
      // It's some other kind of error.
      return Promise.reject(error);
    }
  }
);

axios.interceptors.response.use(
  response => response,  // Pass through successful responses.
  error => {
    if (error.response.status === 401) {
      // The token has expired or is otherwise invalid.
      // Redirect the user to the login page, refresh the token, etc.
      window.location.href = `${url}`;
    } else {
      // It's some other kind of error.
      return Promise.reject(error);
    }
  }
);

// enable withCredentials in axios for the cookie/JWT strategy
axios.defaults.withCredentials = true;

/* Removes double calls when not in development, see this from the docs:
 *
 * Strict mode can’t automatically detect side effects for you, 
 * but it can help you spot them by making them a little more deterministic. 
 * This is done by intentionally double-invoking some of the render phase 
 * methods, including render methods and effects.
 * 
 */
const isDevelopment = process.env.NODE_ENV === 'development';

const AppWithOptionalStrictMode = isDevelopment ? (
  <React.StrictMode>
    <App />
  </React.StrictMode>
) : (
  <App />
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(AppWithOptionalStrictMode);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// Modify this line to adjust web vitals sent to Google Tag Manager's data layer
reportWebVitals((metric) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'web_vitals',
    event_category: 'Web Vitals',
    event_name: metric.name,
    value: Math.round(metric.name === 'CLS' ? metric.delta * 1000 : metric.delta),
    id: metric.id,
    non_interaction: true,
  });
});

