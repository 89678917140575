/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';

import ProfileImage from '../ProfileImage'

import './PictureGallery.css'
import data from './data/lookup.json'

const pattern = /^critter\d{3}\.webp$/;

const PictureGallery = ({ onSelectImage, selectedImage }) => {
  // A shortened list from the server
  // (but, the server could be set-up to configure with admin controls)
  let images = [].concat(
    data['auxonic'],
    data['biotic'], 
    data['eleutheric'], 
    data['genic'], 
    data['kubernetic'], 
    data['polymathic'], 
    data['psionic']
  ); // List of image file names

  const isValidPath = selectedImage != null && pattern.test(selectedImage)
  if (!isValidPath) {
    selectedImage = 'critter021.webp';
  }

  return (
    <div className="picture-gallery">
      {images.map((image, index) => (
        <ProfileImage
          key={index}
          onSelectImage={onSelectImage}
          selectedImage={selectedImage}
          profilePicture={image} />
        )
      )}
    </div>
  );
};

export default PictureGallery;
