/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

import Toggle from '../../components/Toggle';
import OrgMiniList from '../../components/OrgMiniList';
import RecentCosts from '../../components/RecentCosts';

const orgURL = config.createApiUrl('directory', `/`, config.URL_TYPES.BASE);

const GroupSection = ({orgs, eventStats, truncateCharacters}) => {
    const [active, setActive] = useState(false);

    // IDEA: Handle on the backend the if org length is greater than a large number
    return (
      <div className='user_profile_block'>
        <div className='user_profile_heading'>
          <h4>Groups</h4>
          <Link to={`${orgURL}groups/`}>
            <div className='button'>
              View
            </div>
          </Link>
        </div>
        <h6>
          Switch between the summary or view the list of groups you manage
        </h6>
        <h5>
          <Toggle active={active} setActive={setActive} />
        </h5>
        <div>
          {/* ACTIVE */}
          {active && (
            <>
              {/* C2A - Show the list of groups*/}
              {orgs.length > 0 && 
                <OrgMiniList orgs={orgs} truncateCharacters={truncateCharacters}/>
              }

              {/* C2A - Or, encourage Group Signup */}
              {orgs.length < 1 &&
              <div className='c2a'>
                  <h6>
                    Connect with others by creating a group and showcasing what you do!
                  </h6>
                  <b>
                      <Link to={`${orgURL}groups/new/`}>
                          <div className="button gradient_button">
                              Create your own
                          </div>
                      </Link>
                      <br />
                      No hidden fees. It's that simple.
                  </b>
              </div>
              }
            </>
          )}
          {/* INACTIVE */}
          {!active && (
            <RecentCosts eventStats={eventStats}/>
          )}
        </div>
      </div>
    );
  }
export default GroupSection;
