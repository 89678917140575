/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading';
import config from '../../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import './UserList.css'
import ErrorMessage from '../ErrorMessage';

const api = config.createApiUrl('identity', `/user/`, config.URL_TYPES.API);

const SearchNav = ({ search, handleSearch }) => {
    return (
      <div className='search_nav'>
        <Link to="/" className='fa-button search_nav-back'>
            <FontAwesomeIcon
                icon={faArrowLeft}
                size="xl" />
        </Link>
        <div className="search_input-container">
          <input type="text" value={search} onChange={handleSearch} placeholder="Search for a user" />
        </div>
      </div>
    );
  };

const UserList = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    
    const [totalPages, setTotalPages] = useState(0);

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setIsLoading(true);
            const fetchUsers = async () => {
                try {
                    // IDEA: Make this more comprehensive to search
                    const res = await axios.get(`${api}?page=${1}&search=${(search)}`);
                    setUsers(res.data.items);
                    if (search.length > 0) {
                        setTotalPages(1);
                    } else {
                        setTotalPages(res.data.pages);
                    }
                } catch (err) {
                    console.error('Failed to fetch users:', err);
                    setErrorMessage("Sorry, this is closed to the public")
                }
                setIsLoading(false);
            };

            fetchUsers();
        }, 2000); // 2000ms delay

        // This will clear the timer if the user continues to type, ensuring that the API call is only made 2000ms after the user stops typing
        return () => clearTimeout(debounceTimer);
    }, [page, search]);

    const handleSearch = event => {
        setSearch(event.target.value);
      };

    if (isLoading) {
        return (
          <div className='events_wrapper'>
            <Loading />
            <div className='search_nav'>
                <Link to="/" className='fa-button search_nav-back'>
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        size="xl" />
                </Link>
            </div>
          </div>
        )
    }

    if (errorMessage) {
        return (
          <div className='events_wrapper'>
            <ErrorMessage errorMessage={errorMessage} />
            <Link to="/" className='button'>
                <div style={{marginRight: "0.25rem"}}>
                    <FontAwesomeIcon
                        icon={faArrowLeft} />
                </div>
                Back
            </Link>
            <div className='search_nav'>
                <Link to="/" className='fa-button search_nav-back'>
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        size="xl" />
                </Link>
            </div>
          </div>
        )
    }

    return (
        <div>
            <div className='events_wrapper'>
                <div className='events'>
                    {users.length > 0 ? (
                        <>
                            <table className='user-info-account-table'>
                                <tbody>
                                    {users.map(user => (
                                    <tr key={user.id} className='user-info-account-row'>
                                        <td className='user-info-account-row-first' onClick={() => navigate(`/advanced-user-details/${user.id}`)}>
                                            <FontAwesomeIcon icon={faUserCircle} />
                                        </td>
                                        <td>
                                            <div className='user-info_row_data'>
                                                <div className='user-info_row_data_label'>
                                                    Username: 
                                                </div>
                                                <div className='user-info_row_data_value'>
                                                    {user.username ? user.username : '(None)'}
                                                </div>
                                                <div className='user-info_row_data_label'>
                                                    Email: 
                                                </div>
                                                <div className='user-info_row_data_value'>
                                                    {user.email}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <div className="no-events-message">
                            <h1>No Users Found</h1>
                            <h5>Try a Different Search</h5>
                        </div>
                    )}
                </div>
            </div>
            
            <SearchNav search={search} handleSearch={handleSearch}/>
        </div>
    );
};

export default UserList;
