/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import './Toggle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch, faUsersLine } from '@fortawesome/free-solid-svg-icons';
import Switch from '@mui/material/Switch';

const Toggle = ({active, setActive}) => {

  const handleToggle = () => {
    setActive(!active); // Toggle the value of 'active'
  };

  return (
    <div className='toggle-net-container'>
      <div className='toggle-net'>
        <div className='toggle-icon'>
          <FontAwesomeIcon icon={active ? faUsersLine : faStopwatch} />
        </div>
        <div className='toggle-text'>
          {active ? 'List' : 'Summary'}
        </div>
        <div className='toggle-switch'>
          <Switch checked={active} onChange={handleToggle} className="custom-switch"/>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
