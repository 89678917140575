/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

const RecentCosts = ({eventStats}) => {
    const [fullDetails, setFullDetails] = useState(false);

    // Reduce function to accumulate total events
    let totalEvents = eventStats.reduce((total, org) => {
        if ( org.event_count ) {
            return total + org.event_count;
        }
        return total;
    }, 0);

    const optionsDate = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    // IDEA: Refactor to a utility file for all dates in app, assume milliseconds
    const formatDisplayDateFromMilliseconds = (inputDate) => {
        const fDate = new Date(inputDate).toLocaleDateString('en-US', optionsDate);
        
        // Remove leading zero from hours if present
        return fDate.replace(/^0(?:0:)?/, '');
    }

    return (
      <>
        <div className="org-list_subscription_recent_costs-full_details_button"
                onClick={() => setFullDetails(!fullDetails)}>
            <FontAwesomeIcon
                icon={!fullDetails ? faCircleQuestion : faCircleXmark}
                size={"lg"}
            />
            {!fullDetails ? `Learn More` : `Hide Details`}
        </div>
        <div className="org-list_subscription_recent_costs">
            <h3 className="org-title org-list_subscription_recent_costs-amount">
                {totalEvents}
            </h3>
            <h4 className="org-title">
            Events Created
            </h4>
            <h6 className='org-title'>
                Across subscriptions <br />
                during the past week starting on
            </h6>
            <h5 className='org-title'>
                {formatDisplayDateFromMilliseconds(eventStats['startDate'])}
            </h5>
            {fullDetails &&
            <>
                <div className="org-list_subscription_recent_costs-details">
                    <p className='c2a c2a-brighter'>
                        <b>
                            Any subscriptions are billed individually - each event subscription has its own weekly cycle
                        </b>
                    </p>
                    <p>The displayed total includes all created events for all groups</p>
                </div>
                <div className="org-list_subscription_recent_costs-full_details_button"
                        onClick={() => setFullDetails(!fullDetails)}
                        style={{margin: 0}}>
                    <FontAwesomeIcon
                        icon={faCircleXmark}
                        size={"lg"}
                    />
                    Hide Details
                </div>
            </>
            }
        </div>
      </>
    );
  }
export default RecentCosts;
