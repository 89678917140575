/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import './App.css';

import GetStarted from './pages/GetStarted/GetStarted';
import UserList from './components/UserList';

// Password Reset Pages
import ForgotPassword from './pages/ForgotPassword';
import EmailReset from './pages/EmailReset';
import InvalidToken from './pages/InvalidToken';
// User Pages
import UserProfile from './pages/UserProfile';
import UserAudit from './pages/UserAudit';


// Create a custom hook to encapsulate the logic for determining if the nav should be hidden
const useHideNav = (rootPaths) => {
  const location = useLocation(); // Hook to get the current location object
  const hideNav = location.pathname === '/' || rootPaths.some(path => location.pathname.startsWith(path));
  return hideNav;
};

const AppContent = () => {
  const rootPaths = ['/forgot-password', '/reset-password']; // Add other root paths you want to check
  const hideNav = useHideNav(rootPaths);

  useEffect(() => {
    const adjustHeight = () => {
      const mainElement = document.getElementById('main');
      if (mainElement) {
        const navHeight = hideNav ? 0 : 3 * 16; // 3rem to pixels
        mainElement.style.height = `${window.innerHeight - navHeight}px`;
      }
    };

    adjustHeight();
    window.addEventListener('resize', adjustHeight);
    
    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, [hideNav]);

  return (
    <div style={{ display: "flex", width: "100vw" }}>
      <div id="main">
        <div className='content'>
          {/* Your Routes here */}
          <Routes>
            <Route path="/" element={<GetStarted />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<EmailReset />} />
            <Route path="/reset-password-invalid" element={<InvalidToken />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/advanced-user-details/:id" element={<UserAudit />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  console.log('Identity')
  
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
