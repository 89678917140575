/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

import Loading from '../../components/Loading';

import config from '../../config';

import AccountInfo from '../../components/AccountInfo';
import UpdateUserForm from '../../components/UpdateUserForm/UpdateUserForm';
import GroupSection from '../../components/GroupSection'

import ErrorMessage from '../../components/ErrorMessage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { faCalendarDays, faComments, faMagnifyingGlass, faCircleUser } from '@fortawesome/free-solid-svg-icons';

import './UserAudit.css'

const orgAPI = config.createApiUrl('directory', `/org/`, config.URL_TYPES.API);

const forumURL = config.createApiUrl('forum', `/`, config.URL_TYPES.BASE);
const eventURL = config.createApiUrl('events', `/`, config.URL_TYPES.BASE);
const eventStatsURL = config.createApiUrl('events', `/event_stats/org`, config.URL_TYPES.API);

const UserAudit = () => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [needAuth, setNeedAuth] = useState(false);

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    username: '',
    profile_picture: '',
    id: 0,
    isEditMode: false,
  });

  useEffect(() => {
    const api = config.createApiUrl('identity', `/user/${id}`, config.URL_TYPES.API);
    // Fetch user data from API
    setIsLoading(true);
    axios.get(api)
      .then(response => {
        const { 
          name, 
          email, 
          id, 
          username, 
          profile_picture, 
          created_date, 
        } = response.data;
        setUserData({ ...userData, name, email, id, username, profile_picture, created_date });
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        setErrorMessage("Sorry, this is closed to the public")
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  

  const handleEditToggle = () => {
    setUserData({ ...userData, isEditMode: !userData.isEditMode });
  };

  function truncateCharacters(str, maxLength) {
      if (str.length <= maxLength) {
        return str; // Returns original string if string is equal to or less than max length
      } else {
        const truncatedString = str.substring(0, maxLength); // Get the substring up to the maxLength
        // Find the last space within the truncated substring to avoid cutting off a word
        const lastSpaceIndex = truncatedString.lastIndexOf(' ');
        if (lastSpaceIndex !== -1) {
          return truncatedString.substring(0, lastSpaceIndex) + '...'; // Add dots at the last space
        } else {
          return truncatedString + '...'; // If no space found, add dots at the end of the substring
        }
      }
  }

  const OrgListNavWrapper = ({children}) => {
      return (
          <>
              {children}
              {/* Nav */}
              <div className='nav' style={{zIndex: 0}}>
              <Link to="/user-list" className='fa-button search_nav-back'>
                  <FontAwesomeIcon
                      icon={faArrowLeft}
                      size="xl" />
              </Link>
            </div>
          </>
      )
  }

  if (isLoading || needAuth) {
      return (
          <OrgListNavWrapper>
              <Loading />
          </OrgListNavWrapper>
      )
  }

  if (errorMessage) {
      return (
          <OrgListNavWrapper>
              <ErrorMessage errorMessage={errorMessage} />
              <Link to="/" className='button'>
                <div style={{marginRight: "0.25rem"}}>
                      <FontAwesomeIcon
                          icon={faArrowLeft} />
                  </div>
                  Back
              </Link>
              <div className='search_nav'>
                  <Link to="/" className='fa-button search_nav-back'>
                      <FontAwesomeIcon
                          icon={faArrowLeft}
                          size="xl" />
                  </Link>
              </div>
          </OrgListNavWrapper>
      )
  }

  return (
    <OrgListNavWrapper>
      <div className="user-profile">
        <div>
            {/* Account Info */}
            <AccountInfo
              userData={userData}
              setUserData={setUserData}
              truncateCharacters={truncateCharacters} 
              handleEditToggle={handleEditToggle}
              admin={true}
            />

          </div>
      </div>
    </OrgListNavWrapper>
  );
};

export default UserAudit;
