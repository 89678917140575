/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';

import config from '../../config';

import PictureGallery from '../../components/PictureGallery'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';

import './UpdateUserForm.css';
import ErrorMessage from '../ErrorMessage';

const UpdateUserForm = ({userData, setUserData, handleEditToggle}) => {
    const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };
      
    // Add a function to handle image selection
    const handleSelectImage = (image) => {
        setSelectedProfilePicture(image);
        setUserData({ ...userData, profile_picture: image });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Update user data in the backend
        const pattern = /^critter\d{3}\.webp$/;
        let selectedImage = userData.profile_picture;
        const isValidPath = selectedImage != null && pattern.test(selectedImage);
        if (!isValidPath) {
          userData.profile_picture = 'critter021.webp';
        }
    
        const api = config.createApiUrl('identity', `/auth/user/update`, config.URL_TYPES.API);
        
        axios.patch(api, {
          name: userData.name,
          email: userData.email,
          username: userData.username,
          profile_picture: userData.profile_picture,
        })
        .then(response => {
          console.log('Profile updated successfully.');
          setUserData({ ...userData, isEditMode: false });
        })
        .catch(error => {
          setErrorMessage(error?.response?.data?.message)
          console.error('Error updating profile:', error);
        });
      };
    
    return (
    <>
        <div id='update-user-profile'>
            <div className="input-container">
                <h5 className='gallery-copy'>
                    Choose a cute critter as a profile picture. Scroll to see all the options
                </h5>
            </div>
            <PictureGallery selectedImage={userData.profile_picture ? userData.profile_picture : selectedProfilePicture} onSelectImage={handleSelectImage} />
            <div>
        {errorMessage &&
            <ErrorMessage errorMessage={errorMessage} />
        }
                <div className="input-container">
                    <label>
                        Username
                    </label>
                    <input 
                    type="text" 
                    name="username" 
                    value={userData.username ? userData.username : ''} 
                    onChange={handleChange} 
                    />
                </div>
                <div className="input-container">
                    <label>
                        Email
                    </label>
                    <input 
                    type="email" 
                    name="email" 
                    value={userData.email} 
                    onChange={handleChange} 
                    />
                </div>
            </div>
        </div>
        <div className='nav' style={{zIndex: 0}}>
            {/* IDEA: Include back button to manage state reset better */}
            {/* 
            <div onClick={() => handleEditToggle()}
                className="fa-button">
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    size={"xl"}
                />
            </div>
            */}

            <div onClick={handleSubmit}
                className="fa-button">
                <FontAwesomeIcon
                    icon={faSave}
                    size={"xl"}
                />
            </div>
        </div>    
    </>
    );
};

export default UpdateUserForm;
