/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const orgURL = config.createApiUrl('directory', `/`, config.URL_TYPES.BASE);

const OrgMiniList = ({orgs, truncateCharacters}) => {
    return (
      <div className='org-mini-list-container'>
        <div className={`${orgs.length > 2 ? 'org-mini-list' : 'org-mini-list org-mini-list-centered'}`}>
          {orgs.map((org, index) => (
            <div key={index} className='card'>
              <div>
                <h4 className='org-list-label'>
                    {truncateCharacters(org.name, 16)}
                </h4>
                <h6>
                    {truncateCharacters(org.contact_email, 16)}
                </h6>
              </div>
              <Link to={`${orgURL}groups/${org.organization_id}`}>
                  <p className="button">
                      <FontAwesomeIcon
                          icon={faCircleInfo}
                          size={"sm"}
                      />
                      Details
                  </p>
              </Link>
            </div>
          ))}
       </div>
      </div>
    );
  }
export default OrgMiniList;
