/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './EmailReset.css'; // Assume you have some basic CSS for styling

import { useSearchParams, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import ErrorMessage from '../../components/ErrorMessage'

import config from '../../config';

const EmailReset = () => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState(null);
    
  const [showPassword, setShowPassword] = useState(false);
    
  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token")
  const api = config.createApiUrl('identity', `/reset/reset-password/${token}`, config.URL_TYPES.API);

  const navigate = useNavigate()

  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
          handleSubmit(event);
      }
  }    

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Handle password reset request here
    try {
      const response = await axios.post(api, {
          password,
      });

      // Handle the response accordingly
      if (response?.status === 200) {
        setMessage(response.data.message)
      }
    } catch (error) {
      // Handle errors, such as showing a message to the user
      if (error.response.status == 401) {
        navigate('/reset-password-invalid')
      } else if (error.response?.data) {
        setErrorMessage(error.response?.data.message)
      } else {
        setErrorMessage('Unknown error')
      }
      console.error('Error sending password reset email: ', error);
    }
  };

  if (message) {
    return (
      <div className="email-reset-page">
        <ErrorMessage errorMessage={message} />
        <Link to="/" className="button">
          Log In
        </Link>
      </div>
    )
  }

  return (
    <div className="email-reset-page">
      <h1>Update Password</h1>
      <form>
        <div className='input-group'>
            <div className='input-container'>
                <label>
                    Password
                </label>
              <div className='login-input'>
                  <input 
                    type={showPassword ? 'text' : 'password'}
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    onKeyDown={handleKeyDown}
                    placeholder="Password" 
                    required 
                  />
                  <div className="login-input-toggle" onClick={togglePasswordVisibility}>
                      {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                  </div>
              </div>
              <div className="form_help_text">
                  8 characters, includes one of each: uppercase, lowercase, number, special character
              </div>
            </div>
        </div>
        <div className="button" onClick={handleSubmit}>Change Password</div>
      </form>
      {errorMessage &&
        <ErrorMessage errorMessage={errorMessage} />
      }
    </div>
  );
};

export default EmailReset;
